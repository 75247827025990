export default {
    submitShippingReq(path, loginItem, rootState) {
        let formData = new URLSearchParams();

        for (var key in loginItem) {
            formData.append(key, loginItem[key]);
        }

        return {
            url: path,
            options: {
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                },
                body: formData.toString(),
            },
        };
    },

    submitPaymentReq(path, data, rootState) {
        let formData = new URLSearchParams();

        for (var key in data) {
            formData.append(key, data[key]);
        }

        return {
            url: path,
            options: {
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                },
                body: formData.toString(),
            },
        };
    }
};
