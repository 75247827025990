<template lang="html">
    <div class="stepMenuContainer">
        <div class="stepMenu cotacao" :class="{ active: validateStep(1), current: activeStep===1 }" >
            <span>1</span>
            <p class="stepIndicatorName">COTAÇÃO</p>
        </div>
        <div class="stepMenu shipping" :class="{ active: validateStep(2), current: activeStep===2 }" >
            <span>2</span>
            <p class="stepIndicatorName">ENTREGA</p>
        </div>
        <div class="stepMenu payment" :class="{ active: validateStep(3), current: activeStep===3 }" >
            <span>3</span>
            <p class="stepIndicatorName">PAGAMENTO</p>
        </div>
        <!-- <div class="stepMenu payment" :class="{ active: validateStep(4), current: activeStep===4 }" >
            <span>4</span>
            <p class="stepIndicatorName">RESUMO</p>
        </div> -->
    </div>
</template>

<script>
    export default {
        props: ['activeStep'],
        methods: {
            validateStep(step){
                if (step <= this.activeStep) return true;
                else return false;
            }
        }
    }
</script>