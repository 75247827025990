<template>
  <div class="addList_wrap">
    <button v-if="islogged === 'true'" class="add-product-to-product-list icon-pdp-font" :data-pid="productId">
        <span class="icon-favorite_border"></span>
    </button>
    <a v-else class="add-to-productlist-login-btn icon-pdp-font" :href="urllogin" :data-pid="productId">
      <span class="icon-favorite_border"></span>
    </a>
  </div>
</template>

<script>
export default {
  props: ['productId', 'islogged', 'urllogin'],
}
</script>

<style>
  
</style>
