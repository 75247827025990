<template>
    <form
        role="search"
        :action="formaction"
        method="get"
        name="simpleSearch"
        tabindex="0"
        class="searchBar"
        ref="formSearch">

        <input class="form-control searchBar__input search_field js-searchBar__input"
            type="text"
            name="q"
            :placeholder="placeholdertext"
            @input="onSearch()"
            @click="onFocus"
            v-model="searchInputModel"
            autocomplete="off"
        />

        <span v-if="hasSearch(searchSuggestions)" class="search__input-close" @click="removeFocus">Fechar busca <span>&times</span></span>

        <button
            @click="submitForm"
            name="search-button"
            class="searchButton"
            aria-label="Buscar"
            :disabled="disabled"
        >
            <i class="icon-search"></i>
        </button>
        <input type="hidden" :value="locale" name="lang" />

        <div v-if="addOverflowHiddenToBody(searchSuggestions)" class="searchSuggestions">

            <div class="suggestions_modal">
                <div class="suggestions__container">

                    <template v-if="searchSuggestions.popular">
                        <div class="searchSuggestions__mostSearched" v-if="searchSuggestions.popular && searchSuggestions.popular.available && searchSuggestions.popular.phrases.length">
                            <h3 class="searchSuggestions__option--title">
                                Termos mais buscados
                            </h3>

                            <ul class="searchSuggestions__terms">
                                <li v-for="(phrases, index) in searchSuggestions.popular.phrases" :key="index">
                                    <a :href="phrases.url">
                                        <span class="searchSuggestions__badge">{{ index+1 }}</span>
                                        <span class="searchSuggestions__text">{{ phrases.value }}</span>
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div class="searchSuggestions__items" v-if="searchSuggestions.einsteinRecommendations && searchSuggestions.einsteinRecommendations.length">
                            <h3 class="searchSuggestions__option--title">
                                Você pode se interessar
                            </h3>

                            <div class="searchSuggestions__tile">
                                <template v-for="(product) in searchSuggestions.einsteinRecommendations">
                                    <div class="searchSuggestions__product">
                                        <a :href="product.url" :aria-label="product.name">
                                            <div class="searchSuggestions__product--image">
                                                <img
                                                    :alt="'Imagem em miniatura do produto para' + product.name"
                                                    :src="product.imageUrl"
                                                />
                                            </div>
                                        </a>
                                        <div class="searchSuggestions__product--content">
                                            <a :href="product.url" :aria-label="product.name">
                                                <p>{{ product.name }}</p>
                                            </a>
                                            <div class="searchSuggestions__product--productPrice">
                                                <span class="searchSuggestions__product--label">a partir de</span>
                                                <span class="searchSuggestions__product--price">{{ product.price }}</span>
                                            </div>
                                            <div
                                                class="searchSuggestions__product--add js-search-addToCard"
                                                :data-pid="product.id"
                                                :data-url="addtocart"
                                            >
                                                +
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </template>

                    <template v-if="searchSuggestions.category || searchSuggestions.product">
                        <div class="searchSuggestions__mostSearched" v-if="searchSuggestions.category && searchSuggestions.category.available || searchSuggestions.product && searchSuggestions.product.available && searchSuggestions.product.phrases.length">
                            <template v-if="searchSuggestions.product && searchSuggestions.product.available && searchSuggestions.product.phrases.length">
                                <h3 class="searchSuggestions__option--title">
                                    Você está procurando
                                </h3>

                                <ul class="searchSuggestions__terms terms_search">
                                    <li v-for="(phrases, index) in searchSuggestions.product.phrases" :key="index">
                                        <a :href="phrases.url">
                                            <span class="searchSuggestions__icon"><i class="icon-search"></i></span>
                                            <span class="searchSuggestions__text">{{ phrases.value }}</span>
                                        </a>
                                    </li>
                                </ul>
                            </template>

                            <template v-if="searchSuggestions.category && searchSuggestions.category.available && searchSuggestions.category.categories.length">
                                <h3 class="searchSuggestions__option--title">
                                    Buscar em
                                </h3>

                                <ul class="searchSuggestions__terms">
                                    <li v-for="(categories, index) in searchSuggestions.category.categories" :key="index">
                                        <a :href="categories.url">
                                            <span class="searchSuggestions__text">{{ categories.name }}</span>
                                        </a>
                                    </li>
                                </ul>
                            </template>
                        </div>

                        <div
                            :class="this.searchSuggestions.category && this.searchSuggestions.category.categories.length === 0 && this.searchSuggestions.product && this.searchSuggestions.product.phrases.length === 0 ? 'searchFullSuggestions__items' : ''"
                            class="searchSuggestions__items"
                            v-if="searchSuggestions.product && searchSuggestions.product.available && searchSuggestions.product.products.length"
                        >
                            <h3 class="searchSuggestions__option--title">
                                Você pode se interessar
                            </h3>

                            <div class="searchSuggestions__tile">
                                <template v-for="(product) in searchSuggestions.product.products">
                                    <div class="searchSuggestions__product">
                                        <a :href="product.url" :aria-label="product.name">
                                            <div class="searchSuggestions__product--image">
                                                <img
                                                    :alt="'Imagem em miniatura do produto para' + product.name"
                                                    :src="product.imageUrl"
                                                />
                                            </div>
                                        </a>
                                        <div class="searchSuggestions__product--content">
                                            <a :href="product.url" :aria-label="product.name">
                                                <p>{{ product.name }}</p>
                                            </a>
                                            <div class="searchSuggestions__product--productPrice">
                                                <span class="searchSuggestions__product--label">a partir de</span>
                                                <span class="searchSuggestions__product--price">{{ product.price }}</span>
                                            </div>
                                            <div
                                                class="searchSuggestions__product--add js-search-addToCard"
                                                :data-pid="product.id"
                                                :data-url="addtocart"
                                            >
                                                +
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>

                            <a href="#" @click="submitForm()" class="searchSuggestions__link">Ver todos os produtos</a>
                        </div>

                        <div v-else class="searchSuggestions__items" >
                            <h3 class="searchSuggestions__option--title">
                                Nenhum produto encontrado
                            </h3>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
    import $ from 'jquery';
    import { mapGetters } from "vuex";

    export default {
        props: {
            locale: {
                default: null,
                type: String
            },
            placeholdertext: {
                default: null,
                type: String
            },
            addtocart: {
                default: null,
                type: String
            },
            formaction: {
                default: null,
                type: String
            }
        },

        data() {
            return {
                queryValue: '',
                searchInputModel: ''
            };
        },

        computed: {
            ...mapGetters("search", {
                searchSuggestions: "searchSuggestions"
            }),

            disabled(){
                return this.searchInputModel.length<3
            },

            thumbnailCategory(){
                const suggestionCat = this.searchSuggestions && this.searchSuggestions.category ? this.searchSuggestions.category : '';

                if(suggestionCat.available) {
                    return suggestionCat.categories.find(cat => cat.thumbnail !== "");
                }
            }
        },

        mounted() {
            const observer = new MutationObserver(() => {
                const searchModal = this.$refs['formSearch'];
                const shoulClose = searchModal.classList.contains('js-should-close');
                if(shoulClose) {
                    this.removeFocus();
                    $('.searchBar').removeClass('js-should-close');
                }
            });
            const observeWhenAvailable = () => {
                const searchModal = this.$refs['formSearch'];
                if (!searchModal) {
                    setTimeout(observeWhenAvailable, 300);
                    return;
                }
                observer.observe(this.$refs['formSearch'], { attributes: true });
            };
            observeWhenAvailable();
        },

        methods: {
            onSearch() {
                this.$store.dispatch('search/getSearchSuggestions', { searchTerm: this.searchInputModel });
            },

            onFocus() {
                this.$store.dispatch('search/getStartSearch');
                //$('.popover-cep').css('display', 'none');
            },

            removeFocus() {
                this.$store.dispatch('search/removeSearchSuggestions');
                this.searchInputModel = '';
            },

            submitForm() {
                if(!this.disabled) this.$refs.formSearch.submit();
            },

            addOverflowHiddenToBody($obj) {

                const $test = (!!$obj && $obj['product'] && $obj['product'].available) || (!!$obj && $obj['recent'] && $obj['recent'].available) || (!!$obj && $obj['popular'] && $obj['popular'].available);

                if ($test) {
                    $('body').addClass('overflowHidden');
                } else {
                    $('body').hasClass('overflowHidden') && $('body').removeClass('overflowHidden');
                }

                return $test;
            },
            hasSearch($obj) {
                return (!!$obj && $obj['product'] && $obj['product'].available) || (!!$obj && $obj['recent'] && $obj['recent'].available) || (!!$obj && $obj['popular'] && $obj['popular'].available);
            },

        },

    }
</script>
