<template>
    <div>
        <div class="alert alert-danger" role="alert" v-show="loginErr">
            {{ loginErr }}
        </div>

        <form @submit.prevent="onSubmit"  class="login" method="POST" name="login-form">
            <div class="form-group required">
                <label class="form-control-label" for="login-form-email">
                    E-mail
                </label>
                <input
                    type="email"
                    id="login-form-email"
                    v-model="email"
                    placeholder="Digite seu e-mail"
                    required
                    aria-required="true"
                    class="form-control required"
                    :class="{'error': errors.email}"
                    aria-describedby="form-email-error"
                    name="loginEmail"
                >
                <div v-show="errors.email" class="invalid-feedback" id="form-email-error">{{ errors.email }}</div>
            </div>

            <div class="form-group required passwordField">
                <label class="form-control-label" for="login-form-password">
                    Senha
                </label>
                <input
                    type="password"
                    id="login-form-password"
                    v-model="password"
                    placeholder="Digite sua senha"
                    required aria-required="true"
                    class="form-control required"
                    :class="{'error': errors.password}"
                    aria-describedby="form-password-error"
                    name="loginPassword"
                >
                <img
                    :src="iconOpen"
                    alt="visibility-off icon"
                    class="password-visibility-icon"
                    :data-visible="iconOpen"
                    :data-notvisible="iconClosed"
                />
                <div v-show="errors.password" class="invalid-feedback" id="form-password-error">{{ errors.password }}</div>
            </div>

            <div class="clearfix">
                <div class="pforgot-password">
                    <a
                        id="password-reset"
                        class=""
                        title="Esqueceu sua senha?"
                        data-toggle="modal"
                        @click="passwordReset()"
                        data-target="#requestPasswordResetModal">
                        Esqueceu sua senha?
                    </a>
                </div>
            </div>

            <button type="button" class="login-option btn" @click="login()" >Entrar</button>
        </form>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import routes from '../../../api/routes';

    export default {
        data() {
            return {
                email: '',
                password: '',
                errors: {},
                staticUrl: routes.urlStatic,
                PasswordResetUrl: routes.account.passwordResetDialogForm,
            }
        },

        computed: {
            ...mapGetters('checkout', {
                isShareQuotation: 'isShareQuotation',
                addressModal: 'addressModal',
                customerHasProfile: 'customerHasProfile',
                csrf: 'csrf'
            }),

            ...mapGetters('account', {
                logged: 'logged',
                loginErr: 'loginErr',
            }),

            iconOpen(){
                return this.staticUrl + '/password/password-preview-open.svg';
            },

            iconClosed(){
                return this.staticUrl + '/password/password-preview-closed.svg';
            }
        },

        methods: {
            passwordReset(){
                this.$store.commit('account/setShowResetForm', true);
                this.$store.commit('checkout/setIsShareQuotation', false);
            },

            login(){
                $(".login-option").add("is__loading");

                if(this.validateForm()){
                    this.$store.dispatch('account/login', {loginEmail: this.email, loginPassword: this.password, csrf_token: this.csrf.token})
                } else {
                    $(".login-option").removeClass("is__loading");
                }
            },

            validateForm() {
                this.errors = {};

                if (this.email === '') {
                    this.$set(this.errors, 'email', 'Campo obrigatório.');
                } else if (!this.isValidEmail(this.email)) {
                    this.$set(this.errors, 'email', 'E-mail inválido.');
                }

                if (this.password === '') {
                    this.$set(this.errors, 'password', 'Campo obrigatório.');
                }

                return Object.values(this.errors).every(error => !error);
            },

            isValidEmail(email) {
                const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                return emailPattern.test(email);
            }
        }
    }
</script>

<style scoped>
    .invalid-feedback{
        display: block !important;
    }
    .error{
        border-color: #c00;
    }
    .alert{
        font-size: 14px;
    }
</style>

