<template>
    <div class="row">
        <div class="col-12">
            <div class="coupon-code-form">
                <div class="form-group couponCodeBox promoCodeCheckout">
                    <div class="inputCoupon">
                        <input
                            type="text"
                            class="form-control coupon-code-field coupon-code-field-cart"
                            :class="{'is-invalid': couponError, 'is-valid': couponApplied && !couponError}"
                            id="couponCode"
                            name="couponCode"
                            :disabled="couponApplied"
                            v-model="couponCode"
                            placeholder="CUPOM DE DESCONTO" />
                    </div>
                    <div class="promo-code-submit">
                        <button
                            v-if="!couponApplied"
                            type="submit"
                            class="promo-code-btn promo-code-btn-cart"
                            @click="submitCoupon">
                            APLICAR
                        </button>
                        <span v-else class="promo-code-btn-applied">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="check-icon">
                                <path class="svg-fill" d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/>
                            </svg>
                            CUPOM APLICADO
                        </span>
                    </div>
                </div>
                <span v-if="couponApplied" class="promo-code-description-applied">
                    Cupom aplicado com sucesso!
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters('checkout', {
            checkout: "checkout"
        })
    },

    data() {
        return {
            couponCode: '',
            couponError: false,
            couponApplied: false,
        };
    },

    methods: {
        async submitCoupon() {
            this.couponError = false;

            if (this.couponCode === '') {
                this.couponError = true;
                return;
            }

            if (this.couponCode) {
                try {
                    const resultCoupon = await this.$store.dispatch('checkout/addCoupon', this.couponCode);
                    if (resultCoupon.hasOwnProperty('error') && resultCoupon.error) {
                        this.couponError = true;
                        this.couponApplied = false;
                    } else {
                        this.couponApplied = true;
                    }
                } catch (error) {
                    this.couponError = true;
                    this.couponApplied = false;
                }
            }
        }
    }
};
</script>

<style scoped>
.promo-code-btn {
    background: none !important;
}

.promo-code-btn-applied {
    display: flex;
    align-items: center;
    color: #02754b;
    cursor: default;
    font-weight: 700;
}

.promo-code-description-applied {
    margin-top: 8px;
    color: #02754b;
    cursor: default;
    font-weight: 500 !important;
    font-size: 14px;
}

.coupon-code-form .form-control.is-invalid {
    border-color: #c00 !important;
}

.coupon-code-form .form-control.is-valid {
    border-color: #02754b !important;
    border-width: 1px 2px;
    border-style: solid;
}

.promo-code-btn-applied .check-icon {
    width: 15px;
    height: 15px;
    margin-right: 5px;
    fill: #02754b;
    margin-top: 2px;
}
</style>
