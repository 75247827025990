<template lang="html">
    <div class="resumeProducts-overflow">
        <div class="sellerOrderDetail--container" v-for="shippingModel in order.shipping">
            <div style="display: flex; flex-direction: column;">
                <span v-if="shippingModel.store && shippingModel.store.sellerName">Vendido por: <b class="sellerName">{{ shippingModel.store.sellerName }}</b></span>
                <span v-if="shippingModel.store && shippingModel.store.sellerName">Entrega: <b> {{ shippingModel.sellerEstimatedArrivalDate }} </b></span>
            </div>
            <div class="lineItems__container" style="display: flex; flex-direction: column;">
                <div v-for="lineItem in shippingModel.items" class="resumeProductCard sellerOrderDetailProduct--container">
                    <div class="row">
                        <div class="resumeImgBox">
                            <img class="img-fluid"
                                :src="lineItem.images.small[0].url"
                                :alt="lineItem.images.small[0].alt"
                                :title="lineItem.images.small[0].title"/>
                        </div>

                        <div class="nameAndQtt">
                            <h3 class="resumeProductName">{{ lineItem.productName }}</h3>
                            <p class="resumeQttItem"> Quantidade: {{ lineItem.quantity }}</p>
                            <h4 class="resumeQttItem" style="font-weight: bold;"> {{ lineItem.price.sales.formatted }}</h4>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

</template>

<script>
    import { mapGetters } from 'vuex';
    export default {
        props: [],
        methods: {},
        computed: {
            ...mapGetters('checkout', {
                order: 'order',
            }),
        },
    }
</script>
