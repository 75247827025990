<template>
  <div class="breadcrumb__wrap" role="navigation" aria-label="Breadcrumb">
    <ol class="breadcrumb__list" itemprop="breadcrumblist" itemscope="itemscope" itemtype="https://schema.org/BreadcrumbList">
      <li
        class="breadcrumb-item" itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem">
        <a :href="breadcrumbsParsed[0].url" aria-current="page" itemprop="item">
          {{ breadcrumbsParsed[0].htmlValue }}
        </a>
        <meta itemprop="position" content="1" />
        <meta itemprop="name" :content="breadcrumbsParsed[0].htmlValue" />
      </li>

      <span v-if="breadcrumbsParsed.length > 2 && !expanded" class="breadcrumb-item" @click="expandBreadcrumbs">
        ...
      </span>

      <li v-if="expanded"
        v-for="(breadcrumbValue, index) in visibleBreadcrumbs"
         :key="index" class="breadcrumb-item" itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem">
        <a :href="breadcrumbValue.url" aria-current="page" itemprop="item">
          {{ breadcrumbValue.htmlValue }}
        </a>
        <meta itemprop="position" :content="(index + 2).toFixed(0)" />
        <meta itemprop="name" :content="breadcrumbValue.htmlValue" />
      </li>

      <li class="breadcrumb-item" itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem">
        <a :href="breadcrumbsParsed[0].url" aria-current="page" itemprop="item">
          {{ breadcrumbsParsed[breadcrumbsParsed.length - 1].htmlValue }}
        </a>
        <meta itemprop="position" :content="breadcrumbsParsed.length.toFixed(0)" />
        <meta itemprop="name" :content="breadcrumbsParsed[breadcrumbsParsed.length - 1].htmlValue" />
      </li>
    </ol>
  </div>
</template>

<script>
  export default {
      props: ['breadcrumbs'],

      created() {
            this.breadcrumbsParsed = JSON.parse(this.breadcrumbs);
            this.breadcrumbsParsed.pop()
      },
      data() {
          return {
            breadcrumbsParsed: null,
            expanded: false,
          }
      },
      computed: {
        visibleBreadcrumbs () {
        const breadcrumbsLength = this.breadcrumbsParsed.length;
        return breadcrumbsLength >  2 ? this.breadcrumbsParsed.slice(1, breadcrumbsLength - 1) : []
    },
  },
      methods: {
        expandBreadcrumbs() {
          this.expanded = true;
        }
      }
  }
</script>