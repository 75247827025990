import Vue from 'vue'
import Vuex from 'vuex'

import search from './modules/search'
import checkout from './modules/checkout';
import account from './modules/account';
import totals from './modules/totals';

Vue.config.devtools = true;
Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        search,
        checkout,
        account,
        totals
    },
});
