<template>
    <div>
        <template v-for="discount in totals.discounts">
            <template v-if="discount.type === 'coupon'">
                <div :class="'coupon-price-adjustment coupon-uuid-' + discount.UUID" :data-uuid="discount.UUID">
                    <span v-if="discount.applied" class="coupon-applied">Cupom ativo</span>
                    <span v-else class="coupon-not-applied">Não aplicado</span>

                    <div class="coupon-code">{{ discount.couponCode }}
                        <button type="button" class="float-right btn-remove-coupon"
                            :data-code="discount.couponCode"
                            :aria-label="'Remover cupom' + discount.couponCode"
                            data-toggle="modal"
                            data-target="#removeCouponModal"
                            :data-uuid="discount.UUID"
                            @click="setCouponUUID(discount.UUID, discount.couponCode)">
                            <span aria-hidden="true" class="removeCoupomIcon"></span>
                        </button>
                    </div>
                </div>
                <p class="errorRemoveCoupon" v-if="couponError === true && removeUUID === discount.UUID"> {{ couponErrorMsg }} </p>

            </template>
        </template>

        <!--TODO: REMOVER MODAL E ADDER JS IGUAL É HJ PARA  A MODAL ATUALIZAR O CODE -->
        <remove-coupon-modal
            @remove-coupon="removeCoupon"
            :couponCode="couponCode"
            :uuid="removeUUID">
        </remove-coupon-modal>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import RemoveCouponModal from './RemoveCouponModal';

    export default {
        components: {
            RemoveCouponModal
        },

        computed: {
            ...mapGetters('totals', {
            totals: "totals",
            }),

        },

        props: [],

        data() {
            return {
                removeUUID: '',
                couponCode: '',
                couponErrorMsg: '',
                couponError: false,
            };
        },

        methods: {
            setCouponUUID(uuid, couponCode) {
                this.removeUUID = uuid;
                this.couponCode = couponCode;
            },
            async removeCoupon(uuid) {
                this.couponErrorMsg = '';
                this.couponError = false;

                if(uuid){
                    const resultRemoveCoupon = await this.$store.dispatch('checkout/removeCoupon', uuid);

                    if (resultRemoveCoupon.hasOwnProperty('error') && resultRemoveCoupon.error) {
                        this.couponErrorMsg = resultRemoveCoupon.errorMessage;
                        this.couponError = true;
                    }

                    this.removeUUID = '';
                    this.couponCode = '';
                }
            }
        }
    }
</script>




<style scoped>
    .errorRemoveCoupon{
        font-size: 12px;
        color:  #c00;
    }
    .btn-remove-coupon{
        border: 0px;
        background: transparent;
    }
</style>

