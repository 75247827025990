// Components
import SearchBar from './components/SearchBar'
import Breadcrumb from './components/Breadcrumb';
import LongDescription from './components/productPage/LongDescription';
import ProductSummary from './components/productPage/ProductSummary';
import ProductSummaryMobile from './components/productPage/ProductSummaryMobile';
import ProductCard from './components/productPage/ProductCard';
import ProductCarousel from './components/productPage/ProductCarousel';
import GalleryBox from './components/productPage/GalleryBox';
import ProductDatasheet from './components/productPage/ProductDatasheet';
import BuyTogether from './components/productPage/BuyTogether';

// Pages
import Checkout from './pages/Checkout';
import ProductPage from './pages/ProductPage';

export default {
    // Components
    'SearchBar': SearchBar,
    'Breadcrumb': Breadcrumb,
    'LongDescription': LongDescription,
    'ProductSummary': ProductSummary,
    'ProductSummaryMobile': ProductSummaryMobile,
    'ProductCard': ProductCard,
    'ProductCarousel': ProductCarousel,
    'GalleryBox': GalleryBox,
    'ProductDatasheet': ProductDatasheet,
    'BuyTogether': BuyTogether,

    // Pages
    'ProductPage': ProductPage,
    'Checkout': Checkout,
};
