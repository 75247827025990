// VUE init
import Vue from "vue";
import vueComponents from "../vueComponents";
Vue.config.devtools = true;
import store from "../store";

new Vue({
    el: "#vueApp",
    store,
    components: vueComponents
});