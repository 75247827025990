import { render, staticRenderFns } from "./Coupon.vue?vue&type=template&id=a3a37e18&scoped=true"
import script from "./Coupon.vue?vue&type=script&lang=js"
export * from "./Coupon.vue?vue&type=script&lang=js"
import style0 from "./Coupon.vue?vue&type=style&index=0&id=a3a37e18&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a3a37e18",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\JoaoPGoes\\dev\\backlgrs\\Suzano\\backlgrs.sfcc.suzano\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a3a37e18')) {
      api.createRecord('a3a37e18', component.options)
    } else {
      api.reload('a3a37e18', component.options)
    }
    module.hot.accept("./Coupon.vue?vue&type=template&id=a3a37e18&scoped=true", function () {
      api.rerender('a3a37e18', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "cartridges/app_suzano_custom/cartridge/client/default/js/vueComponents/components/Coupon.vue"
export default component.exports