<template>
  <div class="box_wrap">
    <p class="title">{{ title }}</p>

    <div class="product-datasheet-box">
      <table class="product-datasheet">
        <tr v-for="(attr, index) in datasheetMock" :key="index">
          <th>{{ attr.attr }}</th>
          <td>{{ attr.value }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: ['title', 'datasheet'],
  data() {
    return {
      datasheetMock: [
        {
          attr: "Marca",
          value: "Report"
        },
        {
          attr: "Tamanho papel",
          value: "A4"
        },
        {
          attr: "Tipo do papel",
          value: "Sulfit"
        }
      ]
    };
  },
  computed: {
    parsedItems() {
      return JSON.parse(this.datasheet);
    }
  }
}
</script>

<style>
.product-datasheet-box {
  background-color: #fff;
  border-radius: 5px;
  padding: 0px 10px;

  @media (max-width: 992px) {
    border: 1px solid #b3b3b3;
  }
}

.product-datasheet {
  width: 100%;

  tr {
    border-bottom: 1px solid #b3b3b3;
  }

  tr:last-of-type {
    border: none;
  }

  th,
  td {
    padding: 10px 0;
    color: #000;
  }
}
</style>
