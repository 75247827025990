<template>
    <div>
        <select
            id="billingAddressSelector"
            name="addressSelector"
            class="addressSelector form-control"
            @change="addressSelected"
            v-model="selectedAddressIdModel"
            ref="selectAddress"
            >
            <option
                disabled="disabled"
                :value="false"
            >
                Selecione o endereço de cobrança
            </option>
            <template v-if="showNewAddress">
                <option value="new">
                    Novo endereço
                </option>
            </template>
            <template v-if="order.shipping[0].shippingAddress">
                <option disabled>- Remessas Existentes -</option>
                <template v-for="(aShippingModel, index) in order.shipping">
                    <option
                        v-if="aShippingModel.sellerID == 'me'"
                        :value="aShippingModel.UUID"
                    >
                        {{ aShippingModel.shippingAddress && aShippingModel.shippingAddress.firstName || '' }} {{ aShippingModel.shippingAddress && aShippingModel.shippingAddress.lastName || '' }} {{ aShippingModel.shippingAddress && aShippingModel.shippingAddress.address1 || ''}} {{ aShippingModel.shippingAddress && aShippingModel.shippingAddress.city || '' }}{{ aShippingModel.shippingAddress && !aShippingModel.shippingAddress.city || ',' }} {{ aShippingModel.shippingAddress && aShippingModel.shippingAddress.stateCode || ''}} {{ aShippingModel.shippingAddress && aShippingModel.shippingAddress.postalCode || '' }}
                    </option>
                </template>
            </template>

            <template v-if="customer.addresses && customer.addresses.length > 1">
                <option disabled>- Catálogo de endereços -</option>
                <template v-for="address in customer.addresses">
                    <option
                        :value="address.ID"
                    >
                        {{ address.ID || ''}} - {{ address && address.firstName || '' }} {{ address && address.lastName || ''}} {{ address && address.address1 || '' }} {{ address && address.city || ''}}{{ address && !address.city || ',' }} {{ address && address.stateCode||'' }} {{ address && address.postalCode||'' }}
                    </option>
                </template>
            </template>
        </select>
    </div>

</template>

<script>
    import { mapGetters } from "vuex";

    export default {

        props:  {
            billingListModel: {
                default: {},
                type: Object
            },
            showNewAddress: {
                default: false,
                type: Boolean
            }
        },

        computed: {
            ...mapGetters('checkout', {
                customer: 'customer',
                order: 'order',
                billing: "billing"
            }),
        },
        data(){
            return {
                selectedAddressIdModel: null
            }
        },
        created() {
            this.$root.$refs.Address = this;
        },
        methods: {
            populateBillingModel(){
                // console.log("🚀 ~ populateBillingModel ~ populateBillingModel: ", this.selectedAddressIdModel);
                if(this.selectedAddressIdModel){    
                    this.order.shipping.forEach(function(address) {
                        if (address.UUID === this.selectedAddressIdModel) {
                            // console.log("🚀 ~  this.order.shipping: ");
                            // console.log("🚀 ~ this.order.shipping.forEach ~ address:", address)
                            this.billingListModel['dwfrm_billing_addressFields_firstName'] = address.shippingAddress.firstName
                            this.billingListModel['dwfrm_billing_addressFields_lastName'] = address.shippingAddress.lastName
                            this.billingListModel['dwfrm_billing_addressFields_postalCode'] = address.shippingAddress.postalCode
                            this.billingListModel['dwfrm_billing_addressFields_address1'] = address.shippingAddress.address1
                            this.billingListModel['dwfrm_billing_addressFields_at__address__district'] = address.shippingAddress.at_address_district
                            this.billingListModel['dwfrm_billing_addressFields_at__address__number'] = address.shippingAddress.at_address_number
                            this.billingListModel['dwfrm_billing_addressFields_city'] = address.shippingAddress.city
                            this.billingListModel['dwfrm_billing_addressFields_states_stateCode'] = address.shippingAddress.stateCode
                        }
                    }.bind(this));

                    this.customer.addresses.forEach(function(address) {
                        if (address.ID === this.selectedAddressIdModel) {
                            // console.log("🚀 ~ this.customer.addresses.forEach ~ address:", address)
                            // console.log("🚀 ~  this.customer.addresses: ");
                            this.billingListModel['dwfrm_billing_addressFields_firstName'] = address.firstName
                            this.billingListModel['dwfrm_billing_addressFields_lastName'] = address.lastName
                            this.billingListModel['dwfrm_billing_addressFields_postalCode'] = address.postalCode
                            this.billingListModel['dwfrm_billing_addressFields_address1'] = address.address1
                            this.billingListModel['dwfrm_billing_addressFields_at__address__district'] = address.at_address_district
                            this.billingListModel['dwfrm_billing_addressFields_at__address__number'] = address.at_address_number
                            this.billingListModel['dwfrm_billing_addressFields_city'] = address.city
                            this.billingListModel['dwfrm_billing_addressFields_states_stateCode'] = address.stateCode
                        }
                    }.bind(this));
                }

            },
            setIdAddress(){
                // console.log("🚀 ~  setIdAddress:");

                if(this.billing && this.billing.matchingAddressId){
                    this.order.shipping.forEach(function(address) {
                        if (address.UUID === this.billing.matchingAddressId) {
                            this.selectedAddressIdModel = address.UUID
                        }
                    }.bind(this));

                    this.customer.addresses.forEach(function(address) {
                        if (address.ID === this.billing.matchingAddressId) {
                            this.selectedAddressIdModel = address.ID
                        }
                    }.bind(this));
                    this.populateBillingModel();
                }
            },

            addressSelected: function () {
                // console.log("🚀 ~  this.selectedAddressIdModel:",  this.selectedAddressIdModel);

                this.$emit('showAddNewAddress', false);
                this.populateBillingModel();
                this.$store.commit('checkout/setSelectedAddressIdModel', this.selectedAddressIdModel)
            },
        },

        updated() {
            if(this.showNewAddress === true){
                this.selectedAddressIdModel = 'new';
            }
        }
    }
</script>