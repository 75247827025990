const state = () => ({
    totals: {}
});

const getters = {
    totals: (state) => {
        return state.totals;
    }
};

const actions = {
    set({ commit }, totals) {
        commit("set", totals);
    }
};

const mutations = {
    set(state, totals) {
        state.totals = totals;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
