<template>
    <div class="modal fade" id="removeCouponModal" tabindex="-1" role="dialog" aria-labelledby="removeCouponLineItemModal">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header delete-coupon-confirmation-header">
                    <h2 class="modal-title" id="removeCouponLineItemModal">Remover cupom?</h2>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body delete-coupon-confirmation-body">
                    Tem certeza que deseja remover o seguinte cupom do carrinho?
                    <p class="coupon-to-remove">{{ couponCode }}</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-primary" data-dismiss="modal">Cancelar</button>
                    <button type="button" class="btn btn-primary delete-coupon-confirmation-modal-btn"
                            data-dismiss="modal"
                            @click="$emit('remove-coupon', uuid)">
                            Sim
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";

    export default {

        computed: {
            ...mapGetters('totals', {
            totals: "totals",
            }),

        },

        props:  {
            uuid: {
                default: '',
                type: String
            },
            couponCode: {
                default: '',
                type: String
            }
        }
    }
</script>





